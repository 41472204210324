import {
  Box,
  ButtonGroup,
  Container,
  Divider,
  Link,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaInstagram } from "react-icons/fa";
import { HeaderImage } from "../Logo/HeaderImage";
import { Logo } from "../Logo/Logo";
import { FooterLinkList } from "./FooterLinkList";

const SocialButton = ({
  children,
  label,
  href,
  labelLink,
}: {
  children: ReactNode;
  label: string;
  href: string;
  labelLink: String;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "blackAlpha.100")}
      rounded={"full"}
      w={10}
      h={10}
      cursor={"pointer"}
      as={"a"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      href={href}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "blackAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      <Box>{children}</Box>
    </chakra.button>
  );
};

const instagramID = "toranomusuko";
const instagramUrl = `https://www.instagram.com/${instagramID}/`;

export const Footer = () => {
  return (
    <Box
      mt={{ base: 38, md: 100 }}
      bg={useColorModeValue("toramusu.100", "toramusu.100")}
      color={useColorModeValue("gray.700", "gray.700")}
    >
      <Container as={Stack} maxW={"4xl"} py={10}>
        <Stack
          spacing={{ base: "10" }}
          direction={{ base: "column", md: "row" }}
          align="center"
        >
          <Stack direction={{ base: "column", md: "row" }} align="center">
            <Logo logoSize="md" width={170} />
            <HeaderImage logoSize="md" width={230} />
          </Stack>
          <Stack justify="center" direction="row" width="100%" spacing={15}>
            <FooterLinkList />
          </Stack>
        </Stack>
      </Container>

      <Container>
        <Divider borderWidth={1} color="white" />
      </Container>

      <Container py={10}>
        <Stack spacing={{ base: "4", md: "5" }}>
          <Stack justify="space-between" direction="row" align="center">
            <Stack direction="row" align="center">
              <Text fontSize="sm" color="fg.subtle" ml={5}>
                &copy; {new Date().getFullYear()} とらの息子. All rights
                reserved
              </Text>
            </Stack>
            <Stack direction="row" align="center">
              <ButtonGroup variant="tertiary">
                <SocialButton
                  label={"Instagram"}
                  href={instagramUrl}
                  labelLink={instagramID}
                >
                  <FaInstagram />
                </SocialButton>
              </ButtonGroup>
              <Link href={instagramUrl}>{instagramID}</Link>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
