import { Button, Flex, Heading } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

export const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <Flex
      textAlign="center"
      p="10"
      justify="center"
      direction="column"
      align="center"
    >
      <Heading size="4xl" mb="5">
        404
      </Heading>
      <Heading size="xl" mb="5">
        ページが見つかりませんでした。
      </Heading>
      <Button colorScheme="teal" onClick={() => navigate("/")}>
        ホームページへ戻る
      </Button>
    </Flex>
  );
};
