import { Button, useToast } from "@chakra-ui/react";
import { flushDynamoItemsToS3 } from "../../../api/Items";

export const ButtonFlushItem = () => {
  const toast = useToast();

  const onClick = async () => {
    try {
      await flushDynamoItemsToS3();

      toast({
        position: "top",
        title: `商品の反映完了`,
        description: `DBに登録中の商品をitems.jsonへの反映が完了しました`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      toast({
        position: "top",
        title: `商品の反映失敗`,
        description: `DBに登録中の商品をitems.jsonへの反映が失敗しました。ログインし直してから、再実行ください。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Button padding={8} onClick={onClick} colorScheme="green">
      ユーザーが登録商品を見えるように反映する。<br></br>
      商品変更後に実行してください。
    </Button>
  );
};
