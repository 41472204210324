import { UseFormReset } from "react-hook-form";
import { s3Config } from "../aws-export";
import { CategorizedItems, ItemFormValues, MenuItem } from "../types/MenuItem";

export const fetchS3Items = async (
  setItems: React.Dispatch<React.SetStateAction<MenuItem[]>> | null,
  setTodayItems?: React.Dispatch<React.SetStateAction<MenuItem[]>> | null,
  setCategorizedItems?: React.Dispatch<
    React.SetStateAction<CategorizedItems>
  > | null
) => {
  try {
    let response = await fetch(s3Config.URL_PREFIX + "items.json", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    // itemsのカテゴリが"本日の定食"のもの以外を抽出
    const items = data.filter(
      (item: MenuItem) => item.categoryName !== "本日の定食"
    );
    if (setItems) {
      setItems(items);
      localStorage.setItem("items", JSON.stringify(items));
    }

    // itemのカテゴリが"本日の定食"のものだけを抽出
    if (setTodayItems) {
      const todayItems = data.filter(
        (item: MenuItem) => item.categoryName === "本日の定食"
      );
      // 値段でソート
      todayItems.sort((a: MenuItem, b: MenuItem) => {
        if (a.price && b.price) {
          return Number(a.price) - Number(b.price);
        } else {
          return 0;
        }
      });
      setTodayItems(todayItems);
      localStorage.setItem("todayItems", JSON.stringify(todayItems));
    }

    // itemsのカテゴリごとに分類
    if (setCategorizedItems) {
      const categorizedItems: CategorizedItems = {};
      items.forEach((item: MenuItem) => {
        const categoryName = item.categoryName;

        if (categoryName) {
          if (categorizedItems[categoryName]) {
            categorizedItems[categoryName].push(item);
          } else {
            categorizedItems[categoryName] = [item];
          }
        }
      });
      setCategorizedItems(categorizedItems);
      localStorage.setItem(
        "categorizedItems",
        JSON.stringify(categorizedItems)
      );
    }

    // Save the fetched data and the current timestamp to localStorage
    localStorage.setItem("lastFetch", String(Date.now()));
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchItems = async (
  setItems: React.Dispatch<React.SetStateAction<MenuItem[]>>
) => {
  try {
    const response = await fetch(
      "https://324hx4ykg8.execute-api.ap-northeast-1.amazonaws.com/default/toramusuGetItemsInVPC",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    setItems(data);
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchTodayItems = async (
  setTodayItems: React.Dispatch<React.SetStateAction<MenuItem[]>>
) => {
  try {
    const response = await fetch(
      "https://324hx4ykg8.execute-api.ap-northeast-1.amazonaws.com/default/toramusuGetItemsInVPC",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const todayItems = data.filter(
      (item: MenuItem) => item.categoryName === "本日の定食"
    );
    // 値段でソート
    todayItems.sort((a: MenuItem, b: MenuItem) => {
      if (a.price && b.price) {
        return Number(a.price) - Number(b.price);
      } else {
        return 0;
      }
    });
    setTodayItems(todayItems);
  } catch (error) {
    console.error("Error:", error);
  }
};

/**
 * This function can throw an Error if the network response is not ok.
 *
 * @throws {Error}
 */
export const upsertItem = async (
  item: ItemFormValues,
  reset: UseFormReset<ItemFormValues>
) => {
  // "title", "price", "image", "description", "discountPrice", "categoryName";

  // item.imageFileプロパティを削除
  delete item.imageFile;

  if (item.discountPrice === "" || item.discountPrice === null) {
    // discountPriceが空文字列の場合は同じ値にする
    item.discountPrice = item.price;
  }
  // discountPriceが空文字列の場合は同じ値にする
  item.discountPrice = item.price;

  const response = await fetch(
    "https://tmpxls3nm3.execute-api.ap-northeast-1.amazonaws.com/default/toramusuUpsertItems",
    {
      method: "POST",
      body: JSON.stringify(item),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  // const data = await response.json();
  // reset(data);
};

export const activateItem = async (
  item: ItemFormValues,
  activation: boolean
) => {
  const response = await fetch(
    "https://tmpxls3nm3.execute-api.ap-northeast-1.amazonaws.com/default/toramusuUpsertItems",
    {
      method: "POST",
      body: JSON.stringify({ ...item, isActive: activation }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

/**
 * This function can throw an Error if the network response is not ok.
 *
 * @throws {Error}
 */
export const deleteItem = async (
  item: ItemFormValues,
  reset: UseFormReset<ItemFormValues>
) => {
  const response = await fetch(
    "https://tmpxls3nm3.execute-api.ap-northeast-1.amazonaws.com/default/ToramusuDeleteItem",
    {
      method: "POST",
      body: JSON.stringify({
        title: item.title,
        categoryName: item.categoryName,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
  if (!response.ok) {
    console.log("failed to delete item");
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
};

/**
 * This function can throw an Error if the network response is not ok.
 *
 * @throws {Error}
 */
export const flushDynamoItemsToS3 = async () => {
  const response = await fetch(
    "https://tmpxls3nm3.execute-api.ap-northeast-1.amazonaws.com/default/toramusuDailyMenuPutToS3",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  );
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  const data = await response.json();
  console.log(data);
  return data;
};
