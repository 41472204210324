import { Card, CardBody, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { NewsItem } from "../../types/NewsItem";
import { splitBreaks } from "../../utils/splitBreaks";

type Props = {
  newsItem: NewsItem;
};

export const NewsCard = ({ newsItem }: Props) => {
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
      margin={{ base: 1, sm: 3 }}
    >
      <Image
        flex={1}
        objectFit="contain"
        maxW={{ base: "100%", sm: "200px", md: "500px" }}
        maxH={{ base: "130px", sm: "230px" }}
        src={newsItem.image}
        alt={`${newsItem.title}の画像`}
      />

      <Stack align="center" justify="center">
        <CardBody height={{ base: 130, sm: 200 }}>
          <Heading size="md" fontSize={{ base: "90%", sm: "100%" }} as={"h3"}>
            {newsItem.title}
          </Heading>

          <Text py="2" fontSize={{ base: "80%", sm: "100%" }} align="left">
            {splitBreaks(newsItem.content)}
          </Text>
        </CardBody>

        {/* <CardFooter>
          <Button variant="solid" colorScheme="blue">
            Buy Latte
          </Button>
        </CardFooter> */}
      </Stack>
    </Card>
  );
};
