import React, { useContext } from "react";
import { Navigate, RouteProps, useLocation } from "react-router-dom";
import { UserContext } from "../../UserContext";

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const userContext = useContext(UserContext);
  const location = useLocation();

  if (!userContext?.isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};
