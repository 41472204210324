import { Location } from "react-router-dom";
import { scroller } from "react-scroll";

const HEADER_HEIGHT = 70; // ヘッダーの高さ
const SETTIMEOUT_DURATION = 10; // setTimeoutの遅延時間

export const locationScroller = (location: Location) => {
  if (location.hash) {
    let elemId = location.hash.slice(1);
    setTimeout(() => {
      scroller.scrollTo(elemId, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -HEADER_HEIGHT, // ヘッダーの高さ分のオフセットを指定
      });
    }, SETTIMEOUT_DURATION);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, SETTIMEOUT_DURATION);
  }
};
