import { Heading } from "@chakra-ui/react";

type Props = {
  headingText: string;
};

export const H2Heading = ({ headingText }: Props) => {
  return (
    <Heading
      fontWeight={600}
      fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
      lineHeight={"110%"}
      mb={7}
      as={"h2"}
    >
      {headingText}
    </Heading>
  );
};
