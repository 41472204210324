import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { HeaderLogo } from "./HeaderLogo";

type Props = {
  links: { name: string; href: string }[];
};

export const Navigation = ({ links }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        style={{ position: "sticky", top: 0, zIndex: 10 }}
        bg={useColorModeValue("toramusu.100", "toramusu.100")}
        px={4}
      >
        <Container maxW={"container.md"}>
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <HStack spacing={8} alignItems={"center"}>
              <HeaderLogo />
              <HStack
                as={"nav"}
                spacing={10}
                display={{ base: "none", md: "flex" }}
              >
                {links.map((link) => (
                  <NavLink key={link.name} to={link.href}>
                    <Text fontWeight={"bold"}>{link.name}</Text>
                  </NavLink>
                ))}
              </HStack>
            </HStack>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
              bg={useColorModeValue("toramusu.100", "toramusu.100")}
            />
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: "none" }}>
              <Stack as={"nav"} spacing={4}>
                {links.map((link) => (
                  <NavLink key={link.name} to={link.href} onClick={onClose}>
                    <Text fontWeight={"bold"}>{link.name}</Text>
                  </NavLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
};
