import { Container, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchItems } from "../../api/Items";
import { ItemForm } from "../../components/Form/Menu/ItemForm";
import { MenuItem } from "../../types/MenuItem";

export const ItemEditPage = () => {
  const [items, setItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    fetchItems(setItems);
  }, []);

  const handleDelete = (title: string, categoryName: string) => {
    const newItems = items.filter(
      (item) => item.title !== title && item.categoryName !== categoryName
    );
    setItems(newItems);
  };

  // アップデートが完了したら、再度アイテムを取得する
  const handleUpdate = () => {
    fetchItems(setItems);
  };

  return (
    <Container>
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacingX={3} spacingY={0}>
        {items.map((item: MenuItem, index: number) => (
          <ItemForm
            key={item.title + index}
            initialValues={item}
            onDeleteFromList={handleDelete}
            onUpdateFromList={handleUpdate}
            mode="edit"
          />
        ))}
      </SimpleGrid>
    </Container>
  );
};
