import { Button, Container, Input } from "@chakra-ui/react";
import { Amplify, Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { awsConfig } from "../../aws-export";

// ここにCognitoの設定を記述
// aws-exports.tsを参照
Amplify.configure({
  Auth: {
    region: awsConfig.REGION,
    userPoolId: awsConfig.USER_POOL_ID,
    userPoolWebClientId: awsConfig.USER_POOL_APP_CLIENT_ID,
  },
});

export const LoginPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    // Cognitoのユーザー情報を取得して、userに格納
    if (userContext?.isLoggedIn) {
      navigate("/admin");
    }
  }, [navigate, userContext?.isLoggedIn]);

  const signIn = async () => {
    if (!username || !password) {
      console.log("username or password is empty");
      return;
    }

    try {
      const user = await Auth.signIn(username, password);
      userContext?.login(user);
      navigate("/admin");
    } catch (error) {
      console.log(error);
    }
  };

  // ログインしてる場合はユーザー名とログアウトボタンを表示するコンポーネントを返す
  return (
    <Container>
      <Input
        placeholder="username"
        onChange={(event) => setUsername(event.target.value)}
      />
      <Input
        placeholder="password"
        type="password"
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button onClick={signIn}>ログイン</Button>
    </Container>
  );
};
