import { Box, HStack } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { HeaderImage } from "../Logo/HeaderImage";
import { Logo } from "../Logo/Logo";

export const HeaderLogo = () => {
  return (
    <NavLink to="/">
      <HStack>
        <Box>
          <Logo />
        </Box>
        <Box>
          <HeaderImage />
        </Box>
      </HStack>
    </NavLink>
  );
};
