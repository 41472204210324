import { useContext } from "react";

import { Box, Button, Text } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { UserContext } from "../../UserContext";

export const SignOut = () => {
  const userContext = useContext(UserContext);

  const signOut = async () => {
    try {
      await Auth.signOut();
      userContext?.logout();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Text>{`${userContext?.user?.getUsername()}さんでログイン中`}</Text>
      <Button colorScheme="blue" onClick={signOut}>
        ログアウト
      </Button>
    </Box>
  );
};
