import { Box, SimpleGrid } from "@chakra-ui/react";
import { MenuItem } from "../../types/MenuItem";
import { H2Heading } from "../Common/H2Heading";
import { MenuCard } from "./MenuCard";

type Props = {
  categoryName: string;
  items: MenuItem[];
  elementId: string;
};

export const CategorizedMenuBox = ({
  categoryName,
  items,
  elementId,
}: Props) => {
  const noImage = categoryName === "ドリンク";

  return (
    <Box mt="7" id={elementId}>
      <H2Heading headingText={categoryName} />
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacingX={3} spacingY={0}>
        {items.map((item) => (
          <MenuCard
            key={item.title}
            title={item.title}
            price={item.price}
            image={item.image}
            categoryName={item.categoryName}
            description={item.description}
            cardSize="sm"
            noImage={noImage}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
};
