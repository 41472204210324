import { Container, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchTodayItems } from "../../api/Items";
import { ItemForm } from "../../components/Form/Menu/ItemForm";
import { MenuItem } from "../../types/MenuItem";

export const TodayItemEditPage = () => {
  const [todayItems, setTodayItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    fetchTodayItems(setTodayItems);
  }, []);

  // アップデートが完了したら、再度アイテムを取得する
  const handleUpdate = () => {
    fetchTodayItems(setTodayItems);
  };

  return (
    <Container>
      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacingX={3} spacingY={0}>
        {todayItems.map((item: MenuItem, index: number) => (
          <ItemForm
            key={item.title + index}
            initialValues={item}
            onUpdateFromList={handleUpdate}
            mode="edit"
            displayOnDelete={false}
          />
        ))}
      </SimpleGrid>
    </Container>
  );
};
