/*
import {
  ImageProps,
  chakra,
  forwardRef,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import logo from "./logo.svg"

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`

  return <chakra.img animation={animation} src={logo} ref={ref} {...props} />
})
*/
import { Image } from "@chakra-ui/react";

// export const Logo = createIcon({
//   displayName: "Logo",
//   viewBox: "0 0 512 512",
//   path: (
//     <Image
//       src="../../public/images/icon.jpg"
//       alt="とらの息子"
//       boxSize="100%"
//       objectFit="cover"
//     />
//   ),
// });
type Props = {
  logoSize?: "sm" | "md";
  width?: number;
};

const smLogoConfig = {
  width: 55,
  src: "/images/logo.png",
};

const mdLogoConfig = {
  width: 100,
  src: "/images/logo.png",
};

export const Logo = ({ logoSize, width }: Props) => {
  let logoConfig = smLogoConfig;
  if (logoSize === "md") {
    logoConfig = mdLogoConfig;
  }
  return (
    <Image
      src={logoConfig.src}
      alt="とらの息子"
      boxSize="100%"
      objectFit="cover"
      width={width ? width : logoConfig.width}
    />
  );
};
