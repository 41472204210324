export type MenuItem = {
  title: string;
  price: number | string;
  image: string;
  description?: string | null;
  discountPrice?: number | string | null;
  categoryName: string | null;
  isActive?: boolean | null;
};
export type CategorizedItems = Record<string, MenuItem[]> | null;

export type ItemFormValues = MenuItem & {
  imageFile?: FileList;
};

//  "本日の定食",
//   "揚げもの",
//   "炒めもの",
//   "丼もの・カレー",
//   "トッピング",
//   "ドリンク",
//   "その他",
export const CATEGORY_NAMES = [
  {
    name: "本日の定食",
    id: "todays-menu",
    priority: 1,
    display: true,
  },
  {
    name: "揚げもの",
    id: "fried-food",
    priority: 2,
    display: true,
  },
  {
    name: "炒めもの",
    id: "stir-fry-food",
    priority: 3,
    display: true,
  },
  {
    name: "丼もの・カレー",
    id: "bowl-curry",
    priority: 4,
    display: true,
  },
  {
    name: "トッピング",
    id: "topping",
    priority: 5,
    display: false,
  },
  {
    name: "ドリンク",
    id: "drink",
    priority: 6,
    display: false,
  },
  {
    name: "その他",
    id: "other",
    priority: 7,
    display: false,
  },
];
