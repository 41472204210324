import { AspectRatio, Box } from "@chakra-ui/react";

// <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12983.913980077295!2d139.6463481!3d35.5542333!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185f774051265f%3A0x6c5d082ea1c4796b!2z44Go44KJ44Gu5oGv5a2Q!5e0!3m2!1sja!2sjp!4v1688273778193!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
const mapConfig = {
  zoomLevel: 1.2,
  latitude: 35.5540304,
  longitude: 139.64542,
};
const mapUrl = `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12983.913980077295!2d${mapConfig.longitude}!3d${mapConfig.latitude}!3m2!1i1024!2i768!4f${mapConfig.zoomLevel}!3m3!1m2!1s0x60185f774051265f%3A0x6c5d082ea1c4796b!2z44Go44KJ44Gu5oGv5a2Q!5e0!3m2!1sja!2sjp!4v1688273778193!5m2!1sja!2sjp`;

// hiyoshi station 35.5540304, 139.64542;
// musuko 35.5542333, 139.6463481

// とらの息子のGoogleMapの場所をembeddingするコンポーネント
export const Map = () => {
  return (
    <Box id="map">
      <AspectRatio ratio={{ base: 16 / 16, md: 16 / 9 }}>
        <iframe src={mapUrl} title="Google Map of Toramusu" />
      </AspectRatio>
    </Box>
  );
};
