/*
 ChackraUIを利用して、店舗情報の概要を表示するコンポーネント
テーブル形式で表示する。
店舗名、住所、電話番号、営業時間、定休日、席数
店舗名: とらの息子
住所: 神奈川県横浜市港北区日吉２丁目２−１０
電話番号: 045-561-2986
営業時間: 12:00~14:30, 17:30~22:00
定休日: 木曜日
席数: カウンター7席, テーブル1卓5席
 */
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from "@chakra-ui/react";
import { H2Heading } from "../Common/H2Heading";

const fontSize = { md: 24 };
const py = { base: 5, md: 8 };

export const Overview = () => {
  return (
    <Box my={3} id="overview">
      <H2Heading headingText="店舗情報" />
      <TableContainer>
        <Table variant="simple" fontSize={fontSize}>
          <Tbody>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                店舗名
              </Th>
              <Td fontWeight={"bold"}>とらの息子</Td>
            </Tr>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                住所
              </Th>
              <Td>
                <Flex direction={{ base: "column", sm: "column", md: "row" }}>
                  <Text>神奈川県横浜市港北区</Text>
                  <Text>日吉2-2-10</Text>
                </Flex>
              </Td>
            </Tr>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                電話番号
              </Th>
              <Td>045-561-2986</Td>
            </Tr>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                営業時間
              </Th>
              <Td fontWeight={"bold"}>
                <Text>12:00~14:30</Text>
                <br />
                <Text>17:30~22:00</Text>
                <br />
                <Text fontSize={{ base: "sm", md: "md" }}>
                  ※ご飯がなくなり次第終了
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                定休日
              </Th>
              <Td>木曜日</Td>
            </Tr>
            <Tr>
              <Th fontSize={fontSize} isNumeric py={py}>
                席数
              </Th>
              <Td>
                <Text>カウンター7席</Text>
                <br />
                <Text>テーブル1卓5席</Text>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
