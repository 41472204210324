import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";

interface UserContextInterface {
  user: CognitoUser | null;
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  isLoggedIn: boolean;
  login: (user: CognitoUser) => void;
  logout: () => void;
}

const UserContext = React.createContext<UserContextInterface | null>(null);

const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  // Component mount時にlocalStorageからtokenを読み出し、ログイン状態を設定
  useEffect(() => {
    const getLoggedInUser = async () => {
      try {
        // ログインしているCognitoUserを取得
        const loggedInUser = await Auth.currentAuthenticatedUser();
        setUser(loggedInUser);
        setIsLoggedIn(true);
      } catch (error) {
        console.log(error);
        setUser(null);
        setIsLoggedIn(false);
      }
    };

    const token = localStorage.getItem("token");
    if (token) {
      // 同期処理のためにここでloggedIn: trueにしておく
      setIsLoggedIn(true);

      getLoggedInUser();
    }
  }, []);

  const login = (user: CognitoUser) => {
    localStorage.setItem(
      "token",
      user.getSignInUserSession()?.getIdToken().getJwtToken() || ""
    );
    setUser(user);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setIsLoggedIn(false);
  };

  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
