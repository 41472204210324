import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { H2Heading } from "../Common/H2Heading";
import { MenuCard } from "./MenuCard";

const menuItems = [
  {
    title: "揚げもの",
    price: "750～",
    image: "/images/foods/agemono-tonkatu.jpeg",
    cardSize: "sm",
    categoryName: "揚げもの",
    href: "/menu#fried-food",
  },
  {
    title: "炒めもの",
    price: "950～",
    image: "/images/foods/itamemono-sutamina.jpeg",
    cardSize: "sm",
    categoryName: "炒めもの",
    href: "/menu#stir-fry-food",
  },
  {
    title: "丼もの・カレー",
    price: "750～",
    image: "/images/foods/donmono-oyakodon.jpeg",
    cardSize: "sm",
    categoryName: "丼もの・カレー",
    href: "/menu#bowl-curry",
  },
];

export const MenuOverview = () => {
  return (
    <Box my={3}>
      <H2Heading headingText="メニュー" />
      <SimpleGrid columns={{ sm: 2, md: 3 }} spacingX={3} spacingY={0}>
        {menuItems.map((item) => (
          <NavLink key={item.title} to={item.href}>
            <MenuCard
              key={item.title}
              title={item.title}
              price={item.price}
              image={item.image}
              categoryName={item.categoryName}
              cardSize={item.cardSize as "sm" | "md" | undefined}
            />
          </NavLink>
        ))}
      </SimpleGrid>
      <Text mt={4} align={"right"} fontWeight={500} fontSize={"xl"}>
        <NavLink to="/menu">
          もっと見る <ExternalLinkIcon mx="2px" />
        </NavLink>
      </Text>
    </Box>
  );
};
