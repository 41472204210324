import Linkify from "react-linkify";

export const splitBreaks = (text: string) => {
  const splitedText = text.split("\n");
  const lastLineNum = splitedText.length - 1;

  return (
    <>
      {text.split("\n").map((line, index) => (
        <span key={index}>
          <Linkify>{line}</Linkify>
          {index === lastLineNum ? null : <br />}
        </span>
      ))}
    </>
  );
};
