import { Box, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ImageSlider } from "../../components/Hero/ImageSlider";
import { MenuOverview } from "../../components/Menu/MenuOverview";
import { TodaysMenu } from "../../components/Menu/TodaysMenu";
import { News } from "../../components/News/News";
import { Overview } from "../../components/Overview/Overview";
import { Map } from "../../components/StoreLocation/Map";
import { StoreService } from "../../components/StoreService/StoreService";
import { locationScroller } from "../../utils/locationScroller";

export const HomePage = () => {
  const location = useLocation();

  useEffect(() => {
    locationScroller(location);
  }, [location]);

  return (
    <Stack
      as={Box}
      textAlign={"center"}
      spacing={{ base: 4, md: 8 }}
      py={{ base: 10, md: 18 }}
    >
      <ImageSlider />
      <TodaysMenu />
      <MenuOverview />
      <StoreService />
      <News />
      <Overview />
      {/* <BusinessCalendar /> */}
      <Map />
    </Stack>
  );
};
