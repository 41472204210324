// replace the user pool region, id, and app client id details
export const awsConfig = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_V6lqM9Sxd",
  USER_POOL_APP_CLIENT_ID: "3ffiall8qfqpvfq3s05gm05eqn",
  IDENTITY_POOL_ID: "ap-northeast-1:b2d689b6-43c3-4df8-9604-e73db84ede54",
};

export const s3Config = {
  REGION: "ap-northeast-1",
  BUCKET: "toramusu-statics",
  URL_PREFIX: `https://toramusu-statics.s3-ap-northeast-1.amazonaws.com/`,
};

export const s3ConfigImage = {
  REGION: "ap-northeast-1",
  BUCKET: "toramusu-images",
  URL_PREFIX: `https://toramusu-images.s3-ap-northeast-1.amazonaws.com/`,
};
