import { Box } from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { Slide, SlideshowRef } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

type Props = {
  slideToScroll?: number;
  slideToShow?: number;
};

interface SlideImage {
  url: string;
  caption: string;
}

//react-slideshow-image.netlify.app/?path=/docs/examples-fade--default
// The ImageSlider component is a react component that slides 5 images horizontally.
export const ImageSlider = ({ slideToScroll = 1, slideToShow = 1 }: Props) => {
  const slideRef = useRef<SlideshowRef>(null);

  const slideImages: SlideImage[] = useMemo(
    () => [
      {
        url: "/images/hero/toramusu_top.jpg",
        caption: "とらの息子店舗写真",
      },
      {
        url: "/images/foods/agemono-tonkatu.jpeg",
        caption: "メニュー1",
      },
      {
        url: "/images/foods/donmono-oyakodon.jpeg",
        caption: "メニュー2",
      },
      {
        url: "/images/foods/itamemono-sutamina.jpeg",
        caption: "メニュー3",
      },
    ],
    []
  );

  return (
    <>
      <Box>
        <Slide
          slidesToScroll={slideToScroll}
          slidesToShow={slideToShow}
          indicators={true}
          ref={slideRef}
          duration={3000}
          transitionDuration={120}
          arrows={false}
        >
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                key={index}
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              >
                {/* <span style={spanStyle}>{slideImage.caption}</span> */}
              </div>
            </div>
          ))}
        </Slide>
      </Box>
    </>
  );
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: window.innerWidth < 768 ? "300px" : "400px",
  borderRadius: "10px",
};
