import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MenuItem } from "../../types/MenuItem";

type Props = MenuItem & {
  cardSize?: "sm" | "md";
  noImage?: boolean;
};

export const MenuCard = ({
  title,
  price,
  image,
  discountPrice,
  cardSize = "sm",
  noImage = false,
  description = null,
}: Props) => {
  const imageHeight = cardSize === "md" ? 230 : 120;
  const imageWidth = cardSize === "md" ? 360 : 320;
  const boxHeight = cardSize === "md" ? 230 : 120;
  const boxMaxWidth = cardSize === "md" ? 380 : 320;

  return (
    <Center py={1}>
      <Box
        role={"group"}
        p={1}
        maxW={boxMaxWidth}
        w={"full"}
        bg={useColorModeValue("white", "white")}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
      >
        {!noImage && (
          <Box rounded={"lg"} mt={0} pos={"relative"} height={boxHeight}>
            <Image
              rounded={"lg"}
              height={imageHeight}
              width={imageWidth}
              objectFit={"cover"}
              src={image}
              loading="lazy"
              alt={`${title}の画像`}
            />
          </Box>
        )}
        <Flex direction="column" pt={2} align="center" justifyContent="start">
          <Heading
            fontSize={{ base: 13, md: 16 }}
            fontFamily={"body"}
            fontWeight={500}
            as={"h3"}
          >
            {title}
          </Heading>
          <Text>{description}</Text>
          <Stack direction={"row"} align={"center"}>
            <Text fontWeight={800} fontSize={"xl"}>
              {discountPrice ? discountPrice : price}
            </Text>
            {/* {discountPrice && (
              <Text textDecoration={"line-through"} color={"gray.600"}>
                {price}
              </Text>
            )} */}
          </Stack>
        </Flex>
      </Box>
    </Center>
  );
};
