import { useLocation } from "react-router-dom";
import { Navigation } from "./Navigation";

const defaultLinks = [
  { name: "ホーム", href: "/" },
  { name: "メニュー", href: "/menu" },
  { name: "店舗情報", href: "/#overview" },
  { name: "お店・サービス", href: "/#service" },
];

const adminLinks = [
  { name: "管理ホーム", href: "/admin" },
  { name: "本日の定食", href: "/admin/today-items/edit" },
  { name: "新規登録", href: "/admin/items/new" },
  { name: "メニュー編集", href: "/admin/items/edit" },
];

export const Header: React.FC = () => {
  const location = useLocation();

  // location.pathnameが"/admin"を含む場合は、管理画面のAdminNavigationを表示する
  // それ以外の時は、通常のNavigationを表示する
  return (
    <>
      {location.pathname.includes("/admin") ? (
        <Navigation links={adminLinks} />
      ) : (
        <Navigation links={defaultLinks} />
      )}
    </>
  );
};
