import { Box, HStack, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { CATEGORY_NAMES } from "../../types/MenuItem";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const homeLinkList = [
  { name: "店舗情報", href: "/#overview" },
  // { name: "営業カレンダー", href: "/#calendar" },
  { name: "ニュース", href: "/#news" },
  { name: "マップ", href: "/#map" },
];

const homeLink: FooterLinkBoxProps = {
  name: "ホーム",
  items: homeLinkList,
};

const menuLinkList = CATEGORY_NAMES.filter((category) => category.display).map(
  (category) => {
    return {
      name: category.name,
      href: `/menu/#${category.id}`,
      id: category.id,
    };
  }
);

const menuLink: FooterLinkBoxProps = {
  name: "メニュー",
  items: menuLinkList,
};

type FooterLinkBoxProps = {
  name: string;
  items: { name: string; href: string }[];
};

const FooterLinkBox = (linkItem: FooterLinkBoxProps) => {
  return (
    <Box>
      <ListHeader>{linkItem.name}</ListHeader>
      <UnorderedList>
        {linkItem.items.map((link) => (
          <ListItem key={link.name}>
            <NavLink key={link.name} to={link.href}>
              <Text fontWeight={"bold"}>{link.name}</Text>
            </NavLink>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export const FooterLinkList = () => {
  return (
    <HStack spacing={{ base: 8, md: 14 }} justify="start" align="start">
      <FooterLinkBox {...homeLink} />
      <FooterLinkBox {...menuLink} />
    </HStack>
  );
};
