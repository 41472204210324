import { ChakraProvider, Container, Flex, extendTheme } from "@chakra-ui/react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { UserProvider } from "./UserContext";
import { Footer } from "./components/Footer/Footer";
import { PrivateRoute } from "./components/Login/PrivateRoute";
import { Header } from "./components/Navigation/Header";
import { AdminTopPage } from "./pages/Admin/AdminTopPage";
import { ItemEditPage } from "./pages/Admin/ItemEditPage";
import { ItemNewPage } from "./pages/Admin/ItemNewPage";
import { LoginPage } from "./pages/Admin/LoginPage";
import { TodayItemEditPage } from "./pages/Admin/TodayItemEditPage";
import { Error404Page } from "./pages/Client/Error404Page";
import { HomePage } from "./pages/Client/HomePage";
import { MenuPage } from "./pages/Client/MenuPage";

const theme = extendTheme({
  fonts: {
    heading: "'Zen Maru Gothic', sans-serif;",
    body: "'Zen Maru Gothic', sans-serif;",
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "64px",
  },
  colors: {
    toramusu: {
      100: "#f0bf4c",
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "#f7f7f7",
      },
    },
  },
  components: {
    StickyContainer: {
      baseStyle: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        paddingBottom: "4",
      },
    },
    Footer: {
      baseStyle: {
        marginTop: "auto",
      },
    },
  },
});

export const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <UserProvider>
        <Router>
          <Flex direction="column" padding="0" as="div" minHeight={"100vh"}>
            <Header />
            <Container maxW={"container.lg"} flex="1">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/menu" element={<MenuPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute>
                      <AdminTopPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/items/new"
                  element={
                    <PrivateRoute>
                      <ItemNewPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/today-items/edit"
                  element={
                    <PrivateRoute>
                      <TodayItemEditPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin/items/edit"
                  element={
                    <PrivateRoute>
                      <ItemEditPage />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Error404Page />} />
              </Routes>
            </Container>
            <Footer />
          </Flex>
        </Router>
      </UserProvider>
    </ChakraProvider>
  );
};
