import { NewsItem } from "../types/NewsItem";

export const fetchS3NewsItems = async (
  setNewsItems: React.Dispatch<React.SetStateAction<NewsItem[]>>
) => {
  try {
    // let response = await fetch(s3Config.URL_PREFIX + "news.json", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // if (!response.ok) {
    //   throw new Error("Network response was not ok");
    // }
    // const data = await response.json();
    const data = [
      {
        id: 1,
        title: "ポイントカード始めました🐯⭐️",
        content:
          "15個集まると好きなメニューが一品無料で食べれちゃう！\n有効期限はありません！！\n欲しい方、ぜひぜひ店頭でお声がけくださいませ！\nお待ちしております！",
        image: "/images/point-card3_compressed.jpg",
        createdAt: "2021-08-15",
        updatedAt: "2021-08-15",
      },
      {
        id: 2,
        title: "PayPayでの支払いが可能です！",
        content:
          "支払いにPayPayをご利用いただけるようになりました！\n券売機上のQRコードからぜひぜひご利用ください！",
        image: "/images/paypay.jpg",
        createdAt: "2023-10-05",
        updatedAt: "2023-10-05",
      },
      {
        id: 3,
        title: "Instagramやってます",
        content:
          "とらの息子のお手伝いをしてくれているバイトさんから最新情報をお届け！\nぜひ下のリンクからフォローお願いします！\n\nhttps://www.instagram.com/toranomusuko/",
        image: "/images/instagram.png",
        createdAt: "2021-08-15",
        updatedAt: "2021-08-15",
      },
    ];
    setNewsItems(data);
    localStorage.setItem("newsItems", JSON.stringify(data));
  } catch (error) {
    console.error("There was an error!", error);
  }
};
