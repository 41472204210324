import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Container, Divider, Heading, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ButtonFlushItem } from "../../components/Form/Menu/ButtonFlushItem";
import { SignOut } from "../../components/Login/SignOut";

export const AdminTopPage = () => {
  return (
    <Container>
      <Box>
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
          lineHeight={"110%"}
          mb={5}
        >
          トップページ
        </Heading>
        <Text mt={4} fontWeight={500} fontSize={"xl"}>
          <NavLink to="/admin/today-items/edit">
            本日の定食編集 <ExternalLinkIcon mx="2px" />
          </NavLink>
        </Text>
        <Text mt={4} fontWeight={500} fontSize={"xl"}>
          <NavLink to="/admin/items/new">
            メニュー新規登録 <ExternalLinkIcon mx="2px" />
          </NavLink>
        </Text>
        <Text mt={4} fontWeight={500} fontSize={"xl"}>
          <NavLink to="/admin/items/edit">
            メニュー編集 <ExternalLinkIcon mx="2px" />
          </NavLink>
        </Text>
      </Box>
      <Divider mt={5} mb={5} />
      <Box>
        <ButtonFlushItem />
      </Box>
      <Box>
        <SignOut />
      </Box>
    </Container>
  );
};
