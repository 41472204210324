import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { activateItem, deleteItem, upsertItem } from "../../../api/Items";
import { awsConfig, s3Config, s3ConfigImage } from "../../../aws-export";
import { CATEGORY_NAMES, ItemFormValues } from "../../../types/MenuItem";
import { changeExtensionToJPG } from "../../../utils/changeExtensionToJPEG";
import { MenuCard } from "../../Menu/MenuCard";

const s3Client = new S3Client({
  region: s3Config.REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: s3Config.REGION }),
    identityPoolId: awsConfig.IDENTITY_POOL_ID,
  }),
});

interface ItemFormProps {
  initialValues: Partial<ItemFormValues>;
  mode: "create" | "edit";
  onDeleteFromList?: (title: string, categoryName: string) => void; // 追加した削除用関数
  onUpdateFromList?: () => void; // 追加した更新用関数
  displayOnDelete?: boolean; // 追加した削除ボタンの表示
}

export const ItemForm: FC<ItemFormProps> = ({
  initialValues,
  mode,
  onDeleteFromList,
  onUpdateFromList,
  displayOnDelete = true,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ItemFormValues>({
    defaultValues: initialValues,
  });
  const toast = useToast();

  const onSubmit: SubmitHandler<ItemFormValues> = async (data) => {
    const message = mode === "edit" ? "更新" : "登録";

    try {
      // 画像ファイルが存在するときアップロード
      if (data.imageFile && data.imageFile.length > 0) {
        const filename: string = await uploadToS3(data.imageFile[0]);
        // data.imageを画像のURLに変更
        data.image = s3ConfigImage.URL_PREFIX + changeExtensionToJPG(filename);
      }

      await upsertItem(data, reset);
      toast({
        position: "top",
        title: `商品の${message}完了`,
        description: `商品名: ${data.title}の${message}が完了しました`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      if (onUpdateFromList) {
        onUpdateFromList();
      }
    } catch (error) {
      console.log(error);
      toast({
        position: "top",
        title: `商品の${message}失敗`,
        description: `商品名: ${data.title}の${message}が失敗しました。ログインし直してから、再実行ください。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const uploadToS3 = async (file: File) => {
    if (
      !/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/.test(file.name) ||
      !/(jpg|jpeg|png|gif)$/.test(file.type)
    ) {
      alert(".jpg, .png形式の画像ファイルを選択してください");
      throw new Error(".jpg, .png形式の画像ファイルを選択してください");
    }

    // 画像ファイルの名前をランダムな文字列 +拡張子に変更
    // const filename = `${Math.random().toString(36).slice(-8)}.${file.type}`;
    let filename = file.name;
    // filenameからスペースを削除
    filename = filename.replace(/\s+/g, "");

    await s3Client.send(
      new PutObjectCommand({
        Bucket: s3Config.BUCKET,
        Key: filename,
        Body: file,
        ContentType: file.type,
        ACL: "private",
      })
    );
    return filename;
  };

  const onActivate: SubmitHandler<ItemFormValues> = async (data) => {
    try {
      await activateItem(data, true);
      toast({
        position: "top",
        title: `商品の表示完了`,
        description: `商品名: ${data.title}の表示が完了しました`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top",
        title: `商品の表示失敗`,
        description: `商品名: ${data.title}の表示が失敗しました。ログインし直してから、再実行ください。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onUnactivate: SubmitHandler<ItemFormValues> = async (data) => {
    try {
      await activateItem(data, false);
      toast({
        position: "top",
        title: `商品の非表示完了`,
        description: `商品名: ${data.title}の非表示が完了しました`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top",
        title: `商品の非表示失敗`,
        description: `商品名: ${data.title}の非表示が失敗しました。ログインし直してから、再実行ください。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // 新しく作成した削除用関数
  const onDelete: SubmitHandler<ItemFormValues> = async (data) => {
    try {
      await deleteItem(data, reset);
      toast({
        position: "top",
        title: `商品の削除完了`,
        description: `商品名: ${data.title}の削除が完了しました`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      if (onDeleteFromList) {
        onDeleteFromList(data.title!, data.categoryName!);
      }
    } catch (error) {
      toast({
        position: "top",
        title: `商品の削除失敗`,
        description: `商品名: ${data.title}の削除に失敗しました。ログインし直してから、再実行ください。`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      if (error instanceof Error) {
        console.log(typeof error);
        console.log(error.message);
      }
      console.log(error);
      console.log();
    }
  };

  return (
    <VStack
      align="stretch"
      spacing={4}
      backgroundColor={initialValues.isActive ? "none" : "gray"}
    >
      <MenuCard
        title={initialValues.title ?? ""}
        price={initialValues.price ?? ""}
        image={initialValues.image ?? ""}
        categoryName={initialValues.categoryName ?? ""}
        description={initialValues.description ?? null}
        cardSize="sm"
      />

      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          id="item-title"
          isRequired
          isDisabled={mode === "edit"}
          isInvalid={!!errors.title}
        >
          <FormLabel>商品名</FormLabel>
          <Input
            {...register("title", {
              required: "商品名 は必須項目です",
              maxLength: {
                value: 15,
                message: "商品名は15文字以内で入力してください",
              },
            })}
          />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="item-categoryName"
          mt={4}
          isRequired
          isDisabled={mode === "edit"}
          isInvalid={!!errors.categoryName}
        >
          <FormLabel>カテゴリー</FormLabel>
          <Select
            {...register("categoryName", {
              required: "カテゴリー は必須項目です",
            })}
          >
            <option value="">カテゴリー選択</option>
            {CATEGORY_NAMES.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.categoryName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          id="item-price"
          mt={4}
          isRequired
          isInvalid={!!errors.price}
        >
          <FormLabel>価格</FormLabel>
          <Input {...register("price", { required: "価格 は必須項目です" })} />
          <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
        </FormControl>

        <FormControl id="item-imageFile" mt={4} isInvalid={!!errors.imageFile}>
          <FormLabel>Image File</FormLabel>
          <Input type="file" {...register("imageFile")} />
          <FormErrorMessage>{errors.imageFile?.message}</FormErrorMessage>
        </FormControl>

        {mode === "edit" && (
          <Box>
            <FormLabel>画像URL</FormLabel>
            <Link href={initialValues.image} isExternal>
              {initialValues.image}
              <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}

        <FormControl id="item-description" mt={4}>
          <FormLabel>商品説明</FormLabel>
          <Textarea {...register("description")} />
        </FormControl>

        <Button type="submit" colorScheme="blue" mt={4}>
          {mode === "edit" ? "更新" : "登録"}
        </Button>

        {mode === "edit" && (
          <Button
            type="button"
            colorScheme={initialValues.isActive ? "gray" : "yellow"}
            mt={4}
            onClick={handleSubmit(
              initialValues.isActive ? onUnactivate : onActivate
            )}
          >
            {initialValues.isActive ? "メニューから外す" : "メニューに載せる"}
          </Button>
        )}

        {/* 追加した削除ボタン */}
        {mode === "edit" &&
          initialValues.isActive === false &&
          displayOnDelete && (
            <Button
              type="button"
              colorScheme="red"
              mt={4}
              onClick={handleSubmit(onDelete)}
            >
              削除
            </Button>
          )}
      </Box>
    </VStack>
  );
};
