import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { fetchS3Items } from "../../api/Items";
import { MenuItem } from "../../types/MenuItem";
import { H2Heading } from "../Common/H2Heading";
import { MenuCard } from "./MenuCard";

export const TodaysMenu = () => {
  const [todayItems, setTodayItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    fetchS3Items(null, setTodayItems, null);
  }, []);

  return todayItems.length > 0 ? (
    <Box my={3}>
      <Element name="todays-menu">
        <H2Heading headingText="本日の定食" />
      </Element>
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacingX={5} spacingY={0}>
        {todayItems.map((item) => (
          <MenuCard
            key={item.title}
            title={item.title}
            price={item.price}
            image={item.image}
            discountPrice={item.discountPrice}
            categoryName={item.categoryName}
            description={item.description}
            cardSize="md"
          />
        ))}
      </SimpleGrid>
    </Box>
  ) : (
    <></>
  );
};
