import { Image } from "@chakra-ui/react";

type Props = {
  logoSize?: "sm" | "md";
  width?: number;
};

const smLogoConfig = {
  width: 190,
  src: "/images/header-image.png",
};

const mdLogoConfig = {
  width: 100,
  src: "/images/header-image.png",
};

export const HeaderImage = ({ logoSize, width }: Props) => {
  let logoConfig = smLogoConfig;
  if (logoSize === "md") {
    logoConfig = mdLogoConfig;
  }
  return (
    <Image
      src={logoConfig.src}
      alt="とらの息子"
      boxSize="100%"
      objectFit="cover"
      width={width ? width : logoConfig.width}
    />
  );
};
