import { Box, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchS3Items } from "../../api/Items";
import { H2Heading } from "../../components/Common/H2Heading";
import { CategorizedMenuBox } from "../../components/Menu/CategorizedMenuBox";
import { MenuCard } from "../../components/Menu/MenuCard";
import {
  CATEGORY_NAMES,
  CategorizedItems,
  MenuItem,
} from "../../types/MenuItem";
import { locationScroller } from "../../utils/locationScroller";

export const MenuPage = () => {
  const [, setItems] = useState<MenuItem[]>([]);
  const [todayItems, setTodayItems] = useState<MenuItem[]>([]);
  const [categorizedItems, setCategorizedItems] =
    useState<CategorizedItems>(null);

  useEffect(() => {
    fetchS3Items(setItems, setTodayItems, setCategorizedItems);
  }, []);

  const location = useLocation();

  useEffect(() => {
    locationScroller(location);
  }, [location]);

  return (
    <Box my={3} mx={0}>
      {todayItems.length > 0 && (
        <Box id="todays-menu">
          <H2Heading headingText="本日の定食" />
          <SimpleGrid columns={{ base: 2 }} spacingX={3} spacingY={0}>
            {todayItems.map((item) => (
              <MenuCard
                key={item.title}
                title={item.title}
                price={item.price}
                image={item.image}
                categoryName={item.categoryName}
                description={item.description}
                cardSize="sm"
              />
            ))}
          </SimpleGrid>
        </Box>
      )}
      {categorizedItems &&
        CATEGORY_NAMES.sort((a, b) => a.priority - b.priority).map(
          (category) => {
            const items = categorizedItems[category.name];
            if (items) {
              return (
                <CategorizedMenuBox
                  key={category.name}
                  elementId={category.id}
                  categoryName={category.name}
                  items={items}
                />
              );
            }
            return null; // カテゴリに該当するアイテムが存在しない場合は何も描画しない
          }
        )}
    </Box>
  );
};
