import { Container, SimpleGrid, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchItems } from "../../api/Items";
import { ItemForm } from "../../components/Form/Menu/ItemForm";
import { MenuCard } from "../../components/Menu/MenuCard";
import { MenuItem } from "../../types/MenuItem";

export const ItemNewPage = () => {
  const [items, setItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    fetchItems(setItems);
  }, []);

  // 登録が完了したら、再度アイテムを取得する
  const handleUpdate = () => {
    fetchItems(setItems);
  };

  return (
    <Container>
      {/* 商品新規登録 */}
      <Stack>
        <ItemForm
          initialValues={{}}
          mode="create"
          onUpdateFromList={handleUpdate}
        />
      </Stack>
      <Container my={3} mx={0}>
        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4 }}
          spacingX={3}
          spacingY={0}
        >
          {items.map((item: MenuItem, index: number) => (
            <MenuCard
              key={item.title + index}
              title={item.title ?? ""}
              price={item.price ?? ""}
              image={item.image ?? ""}
              description={item.description}
              categoryName={item.categoryName ?? ""}
              cardSize="sm"
            />
          ))}
        </SimpleGrid>
      </Container>
    </Container>
  );
};
