import { Box, Text } from "@chakra-ui/react";
import { H2Heading } from "../Common/H2Heading";

/*
画面サイズがモバイルサイズのときに、<br>タグを入れる
*/
const AdjustBrTag = () => {
  const isSmallScreen = window.innerWidth < 768;
  return isSmallScreen ? <br /> : null;
};

export const StoreService = () => {
  return (
    <Box my={3} id="service">
      <H2Heading headingText="お店・サービス" />
      <Text fontSize={{ base: "md", lg: "lg" }} color="fg.subtle" my={5}>
        <Text as="span" fontWeight="bold">
          日吉駅徒歩２分
        </Text>
        にある
        <AdjustBrTag />
        地域密着型の町の定食屋
        <AdjustBrTag />「
        <Text as="span" fontWeight="bold">
          とらの息子
        </Text>
        」です！
      </Text>
      <Text fontSize={{ base: "md", lg: "lg" }} color="fg.subtle" my={5}>
        <Text as="span" fontWeight="bold">
          肉中心のボリューム満点で
          <AdjustBrTag />
          ガッツリした定食
        </Text>
        を取り揃えて、
        <AdjustBrTag /> 学生向け価格でご提供しています。
      </Text>
      <Text
        fontSize={{ base: "md", lg: "lg" }}
        color="fg.subtle"
        fontWeight="bold"
        my={5}
      >
        ご飯のおかわり無料サービス付きです！
      </Text>
      <Text fontSize={{ base: "md", lg: "lg" }} color="fg.subtle" my={5}>
        {/* ランチタイムは通常よりお買い得なランチセットをご用意しています。 */}
        いつも気さくな小林シェフが
        <AdjustBrTag />
        みなさまのご来店をお待ちしています。
      </Text>
    </Box>
  );
};
