import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fetchS3NewsItems } from "../../api/News";
import { NewsItem } from "../../types/NewsItem";
import { H2Heading } from "../Common/H2Heading";
import { NewsCard } from "./NewsCard";

export const News = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

  useEffect(() => {
    fetchS3NewsItems(setNewsItems);
  }, []);

  return (
    <Box my={3} id="news">
      <H2Heading headingText="ニュース" />
      <Box>
        {newsItems.map((newsItem) => (
          <NewsCard key={newsItem.id} newsItem={newsItem} />
        ))}
      </Box>
    </Box>
  );
};
